
import { defineComponent } from 'vue';
import embed, { VisualizationSpec } from 'vega-embed';

function spec(): VisualizationSpec {
  return {
    config: {
      background: '#333',
      title: { color: '#fff' },
      style: { 'guide-label': { fill: '#fff' }, 'guide-title': { fill: '#fff' } },
      axis: { domainColor: '#fff', gridColor: '#888', tickColor: '#fff' }
    },
    $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
    width: 'container',
    height: 'container',
    title: 'Gewicht',
    selection: {
      grid: {
        type: 'interval',
        bind: 'scales',
        encodings: ['x']
      }
    },
    data: {
      url: '/weights.json'
    },
    transform: [
      { sample: 1000 },
      { calculate: 'datum.timestamp/3600', as: 'hour' }
    ],
    mark: { type: 'line', interpolate: 'step' },
    encoding: {
      x: {
        field: 'hour',
        type: 'quantitative',
        axis: {
          title: 'min. Spielzeit in Stunden',
          titleFontSize: 16,
          labelFontSize: 12
        }
      },
      y: {
        field: 'value',
        type: 'quantitative',
        axis: {
          title: 'Gewicht',
          titleFontSize: 16,
          labelFontSize: 12
        }
      },
      color: {
        field: 'type',
        legend: {
          labelExpr: "datum.value == 'cur' ? 'Aktuell' : 'Maximual'",
          title: '',
          labelFontSize: 16
        },
        scale: { range: ['#1f77b4', '#d62728'] }
      }
    }
  };
}

export default defineComponent({
  name: 'WeightStatistics',
  setup() {
    embed('#player-weight-stats', spec(), { actions: true });
  },
});

