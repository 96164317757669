
import { defineComponent } from 'vue';
import WeightStatistic from '@/components/WeightStatistic.vue';
import SeriesData from '@/components/SeriesData.vue';
import GoldStatistic from '@/components/GoldStatistic.vue';

export default defineComponent({
  name: 'Home',
  components: {
    GoldStatistic,
    SeriesData,
    WeightStatistic
  },
});
